.Post {
    display: flex;
    width: 100%;
    max-width: 1000px;
    flex-direction: column;
    justify-content: center;
    padding: 10px 10px 0px 10px;
    border: 2px solid rgba(0, 0, 0, 0.078);
}

.postHeader {
    display: flex;
    justify-content: space-between;
}

.Post h2 {
    color: black;
    /* font-size: 1rem; */
    padding-bottom: 5px;
}

.PostDate {
    color: black;
    font-size: smaller;
}

.PostBody {
    display: inline-block;
    padding: 20px 10px;
    border-radius: 1rem;
    /* white-space: nowrap; */
}

.likePanel {
    display: flex;
    justify-content: space-around;
    border-radius: 1rem;
    background-color: var(--mainFrameColor);
    z-index: 0.1;
}

.likePanel button {
    border: 0px;
    background-color: inherit;
    cursor: pointer;
}

.likePanel img {
    width: 50px;
    height: 50px;
}

.DeleteButton {
    /* width: 50px;
    height: 20px;
    background-color: red; */
    border: 0;
    color: aliceblue;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
    margin-left: 10px;
}

.EditButton {
    color: rgb(0, 195, 255);
    border: 0;
    font-weight: bold;
    border-radius: 10px;
    cursor: pointer;
}

.comments {
    /* padding: 1rem 0; */
    border: solid #000 1px;
    border-radius: 1rem;
    margin: 1rem 0;
}

.comments h3 {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: bolder;
}

.commentMessage {
    margin: 1rem;
}

#addComments {
    padding: 1rem;
}

#addComments input[type='text'] {
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 75%;
    margin: 0 1rem;
    border: solid #000 1px;
}

#addComments input[type='submit'] {
    padding: 0.5rem;
    border-radius: 0.5rem;
    width: 20%;
    background-color: var(--green);
    color: var(--white);
    border: solid #000 1px;
    cursor: pointer;
}

.Post a:-webkit-any-link {
    text-decoration: none;
}

.PostNotFound {
    margin-top: 20px;
    color: red;
    text-decoration: underline;
}