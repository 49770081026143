/* sign up style */
#signup {
    border-radius: 10px;
    display: none;
    flex-direction: column;
    top: 10%;
    /* left: 55%; */
    border: 2px solid lightgoldenrodyellow;
    border-radius: 5px;
    width: 40%;
    background-color: rgba(247, 245, 245, 0.85);
    z-index: 100;
    position: absolute;
}

#signup h3 {
    font-size: 30px;
    display: inline;
}

#signup input {
    width: 95%;
    margin: 20px;
    padding: 5px;
    font-size: 20px;
    border: 1px solid black;
    border-radius: 5px;
}

#signup input[type=radio] {
    width: 10px;
}

#signup .inputEle {
    display: block;
}

#signup .dob {
    padding-left: 20px;
    font-weight: bold;
}

#signup input[type=submit] {
    width: 30%;
    margin: 10px 20px;
    background-color: seagreen;
    color: white;
    padding: 10px;
    font-size: 20px;
    border-radius: 5px;
    cursor: pointer;
}

.signupMain {
    margin: 10px 20px;
    font-size: 20px;
    padding: 10px;
    background-color: rgb(4, 4, 238);
    color: white;
    border-radius: 5px;
    border: 1px solid black;
}

.signupMain:hover {
    color: aliceblue;
    text-decoration: none;
}