.mainPagePost {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.078); */
}

.mainPagePost h2 {
  color: black;
  /* font-size: 1rem; */
  padding-bottom: 5px;
}

.mainPagePostDate {
  color: black;
  font-size: smaller;
}

.mainPagePostBody {
  display: inline-block;
  height: 1.5rem;
  padding: 10px 0px 20px 0px;
  margin-bottom: 1rem;
  word-wrap: break-word;
  overflow: hidden;

  /* white-space: nowrap; */
}

/* .DeleteButton {
  width: 50px;
  height: 20px;
  background-color: red;
  border: 0;
  color: aliceblue;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.EditButton {
  width: 50px;
  height: 20px;
  background-color: rgb(0, 0, 0);
  border: 0;
  color: aliceblue;
  font-weight: bold;
  border-radius: 10px;
  cursor: pointer;
} */

.mainPagePost a:-webkit-any-link {
  text-decoration: none;
}

.PostNotFound {
  margin-top: 20px;
  color: red;
  text-decoration: underline;
}