* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App {
  display: flex;
  width: 100%;
  /* max-width: 640px; */

  height: 100vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-family: Arial, Helvetica, sans-serif;
}

main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
}
