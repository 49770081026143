.Header {
  display: flex;
  width: 100%;
  height: fit-content;
  background-color: var(--mainFrameColor);
  color: var(--black);
  padding: 5px;
  justify-content: space-between;
  align-items: center;
}

.Header h1 {
  margin-left: 1rem;
  font-size: 2rem;
}

.Header img {
  height: 40px;
  width: 40px;
  margin-right: 1rem;
}

.Header button {
  /* background-color: var(--green); */
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  color: var(--white);
  border-radius: 0.5rem;
  border: 1rem;
}

#profileDropDown {
  border-radius: 10px;
  display: none;
  flex-direction: column;
  top: 10%;
  left: 55%;
  border: 2px solid lightgoldenrodyellow;
  border-radius: 5px;
  width: 40%;
  background-color: rgba(247, 245, 245, 0.85);
  z-index: 100;
  position: absolute;
}